import type { ComponentType } from 'react';
import type { Params } from 'react-router-dom';
import * as Containers from '../containers';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import Forbidden from '../components/Forbidden';

export const TRANSITION_DEFAULT = {
    classNames: 'fade',
    timeout: { enter: 250, exit: 250 }
};

export type RouteComponent = ComponentType<any>;
export type TransitionMetaData = typeof TRANSITION_DEFAULT;

export type Route = Readonly<{
    name: string;
    path: string;
    icon?: IconProp;
    showInNav?: boolean;
    isPrivate?: boolean;
    Component: RouteComponent;
    transition: TransitionMetaData;
    params?: Readonly<Params<string>>;
}>;

export const Routes: Route[] = [
    {
        showInNav: false,
        isPrivate: true,
        name: 'Home',
        path: '/',
        Component: Containers.FxRatePage,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: true,
        isPrivate: true,
        name: 'Fx Rate',
        path: '/fxrate',
        Component: Containers.FxRatePage,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: true,
        isPrivate: true,
        name: 'Email List',
        path: '/ratesubscription',
        Component: Containers.RateSubscriptionPage,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: false,
        isPrivate: false,
        name: 'Forbidden',
        path: '/forbidden',
        Component: Forbidden,
        transition: TRANSITION_DEFAULT
    }
];
