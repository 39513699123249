import { RateSubscriptionApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

export type RateSubscriptionModel = Readonly<{
    id: number
    companyId: string;
    emailAddress: string;
}>;

export type RateSubscriptionPageState = Readonly<{
    isLoading: boolean;
    rateSubscriptionData: RateSubscriptionModel[];
}>;

export type ReceiveRequestsPayload = Pick<RateSubscriptionPageState, "rateSubscriptionData">;

const initialState: RateSubscriptionPageState = {
    rateSubscriptionData: [],
    isLoading: false
};

export const RateSubscriptionSlice = createSlice({
    name: 'RateSubscription',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        receiveRequests: (state, action: PayloadAction<ReceiveRequestsPayload>) => {
            state.isLoading = false;
            const { rateSubscriptionData } = action.payload;
            state.rateSubscriptionData = rateSubscriptionData;
        }
    }
});

export const getRateSubscriptionsAsync = createAsyncThunk(
    'rateSubscription/getRateSubscriptionsAsync',
    async (_, { dispatch }) => {

        // Dispatch request to intialize loading phase
        dispatch(startLoading());

        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            const rateSubscriptionData = await RateSubscriptionApi.getRateSubscriptions();
            dispatch(stopLoading());
            const payload = { rateSubscriptionData };
            dispatch(receiveRequests(payload));
            return null;
        } catch (e) {
            dispatch(stopLoading());
            console.error(e);
        }
    }
);

export const postSubscriptionAsync = createAsyncThunk(
    'rateSubscription/postSubscriptionAsync',
    async (rateSubscription: RateSubscriptionModel, { dispatch }) => {
        try {
            dispatch(startLoading());
            const newLookup = await RateSubscriptionApi.postSubscriptionAsync(rateSubscription);
            dispatch(stopLoading());
            dispatch(getRateSubscriptionsAsync());
            return newLookup;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.response?.data;
            }
            return e;
        }
    }
);

export const deleteSubscriptionAsync = createAsyncThunk(
    'admin/deleteSubscriptionAsync',
    async (id: number, { dispatch }) => {
        try {
            dispatch(startLoading());
            const status = await RateSubscriptionApi.deleteSubscriptionAsync(id);
            dispatch(stopLoading());
            return status;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                return e.response?.data;
            }
            return e;
        }
    }
);

export const { startLoading, stopLoading, receiveRequests } = RateSubscriptionSlice.actions;

export default RateSubscriptionSlice.reducer;