/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import * as Yup from 'yup';
import {
    DataGridPro,
    type GridRowId,
    type GridValidRowModel,
    type DataGridProProps,
    useGridApiRef,
    GridActionsCellItem,
    type GridRowModel,
    type GridRowModesModel,
    type GridColumnVisibilityModel,
    type GridColDef,
    type GridCellParams,
    type MuiEvent,
    GridRowModes
} from '@mui/x-data-grid-pro';
import { Alert, Snackbar, Stack, Toolbar, type AlertProps } from '@mui/material';
import Button from '@mui/material/Button';
import RestoreIcon from '@mui/icons-material/Restore';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { darken } from '@mui/material/styles';
import { useAppDispatch } from '../../store';
import { saveFxRateRequestAsync, type FxRatePageModel, type FxRatePageViewModel } from '../../store/fxRateSlice';
import { FxRateGridColumns } from './FxRateGridColumns';
import FxRateGridToolbar from './FxRateGridToolbar';
import { FxRateSchema } from './FxRateSchema';
import fileDownload from 'js-file-download';
import { FxRateApi } from '../../api';

interface FxRateGridProps {
    fxRateData: FxRatePageViewModel
}
const FxRateGrid = React.memo<FxRateGridProps>(({ fxRateData }) => {
    const dispatch = useAppDispatch();
    const [lastMaintenanceDateTime, setLastMaintenanceDateTime] = React.useState<Date>(fxRateData.lastMaintenanceDateTime);
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [page, setPage] = React.useState<number>(0);
    const [rows, setRows] = React.useState<FxRatePageModel[]>(fxRateData.fxRateRequests);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({
            id: true,
            currencyMasterId: false,
            currencyName: true,
            currencySymbol: true,
            terms: true,
            offerSpreadPct: true,
            offerMarketRate: true,
            offerSpreadBps: true,
            offerCustomerRateInternal: true,
            offerCustomerRateExternal: true,
            bidSpreadPct: true,
            bidMarketRate: true,
            bidSpreadBps: true,
            bidCustomerRateInternal: true,
            bidCustomerRateExternal: true,
            fxlastMaintDateTime: true,
            updatedDate: true,
            updatedBy: true,
            rateFileCreated: false,
            fileUpdateType: true,
            isActive: true
        });

    const [snackbar, setSnackbar] = React.useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    React.useEffect(() => {
        setRows(fxRateData.fxRateRequests);
    }, [fxRateData.fxRateRequests]);

    React.useEffect(() => {
        setLastMaintenanceDateTime(fxRateData.lastMaintenanceDateTime);
    }, [fxRateData.lastMaintenanceDateTime]);

    const handleCellDoubleClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
        event.defaultMuiPrevented = true;
        setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
    };

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const gridColumns = FxRateGridColumns();
    const apiRef = useGridApiRef();

    const [hasUnsavedRows, setHasUnsavedRows] = React.useState(false);
    const unsavedChangesRef = React.useRef<{
        unsavedRows: Record<GridRowId, GridValidRowModel>;
        rowsBeforeChange: Record<GridRowId, GridValidRowModel>;
    }>({
        unsavedRows: {},
        rowsBeforeChange: {},
    });
    const [isSaving, setIsSaving] = React.useState(false);
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);

    const columns = React.useMemo<GridColDef[]>(() => {
        return [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                getActions: ({ id, row }: { id: any, row: any }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />,
                        ];
                    }
                    return [
                        <GridActionsCellItem
                            icon={<RestoreIcon />}
                            label="Discard changes"
                            disabled={unsavedChangesRef.current.unsavedRows[id] === undefined}
                            onClick={() => {
                                apiRef.current.updateRows([
                                    unsavedChangesRef.current.rowsBeforeChange[id],
                                ]);
                                delete unsavedChangesRef.current.rowsBeforeChange[id];
                                delete unsavedChangesRef.current.unsavedRows[id];
                                setHasUnsavedRows(
                                    Object.keys(unsavedChangesRef.current.unsavedRows).length > 0,
                                );
                                setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
                            }}
                        />,
                    ];
                },
            },
            ...gridColumns,
        ];
    }, [gridColumns, unsavedChangesRef, apiRef]);

    const processRowUpdate: NonNullable<DataGridProProps['processRowUpdate']> = (
        newRow,
        oldRow,
    ) => {
        var errorMsg = ValidateRowData(newRow);
        if (errorMsg) {
            throw new Error(errorMsg);
        }
        const rowId = newRow.id;
        unsavedChangesRef.current.unsavedRows[rowId] = newRow;
        if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
            unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
        }
        setHasUnsavedRows(true);
        return newRow;
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.id === 0) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const ValidateRowData = (row: FxRatePageModel): string => {
        try {
            Yup.object().shape(FxRateSchema).validateSync(row, { abortEarly: false });
        } catch ({ errors }) {
            var err = JSON.stringify(errors, null, 2);
            //console.log(err);
            return err;
        }
        return '';
    };

    const UpdateRow = React.useCallback(
        (rows: GridRowModel[], lastMaintenanceDateTime: Date) =>
            new Promise<Partial<string>>(async (resolve, reject) => {
                //console.log(newRow);
                var errorMsg = '';
                var fxRecords = [];
                for (var i = 0; i < rows.length; i++) {
                    var newRow = rows[i];
                    var data: FxRatePageModel = {
                        id: newRow.id,
                        currencyMasterId: newRow.currencyMasterId,
                        cfLastOriginal: newRow.cfLastOriginal,
                        cfLastMarkup: newRow.cfLastMarkup,
                        cfLastFinal: newRow.cfLastFinal,
                        cfLastFinalInverse: newRow.cfLastFinalInverse,
                        spreadPercentage: newRow.spreadPercentage,
                        lastMaintenanceDateTime: newRow.lastMaintenanceDateTime,
                        webUpdatedDate: newRow.webUpdatedDate,
                        webUpdatedBy: newRow.webUpdatedBy,
                        isActive: newRow.isActive,
                        currencyStatus: newRow.currencyStatus,
                        currencyName: newRow.currencyName,
                        currencyCode: newRow.currencySymbol,
                        precision: newRow.precision,
                    }
                    errorMsg = errorMsg + ValidateRowData(data);
                    fxRecords.push(data);
                }
                if (errorMsg !== '') {
                    reject(new Error(errorMsg));
                } else {
                    var requestData: FxRatePageViewModel = {
                        fxRateRequests: fxRecords,
                        lastMaintenanceDateTime: lastMaintenanceDateTime,
                        isUserReadOnly: false
                    };
                    const response = await dispatch(saveFxRateRequestAsync(requestData));
                    if (response.payload.success) {
                        var msg = response.payload.success + '';
                        resolve(msg);
                    } if (response.payload.warning) {
                        msg = response.payload.warning + '';
                        for (i = 0; i < response.payload.allEdited.length; i++) {
                            apiRef.current.updateRows([
                                response.payload.allEdited[i],
                            ]);
                        }
                        reject(new Error(msg));
                    } else
                        reject(new Error("Error while saving data: " + response.payload));
                }
            }),
        [dispatch]
    );

    const discardChanges = () => {
        setHasUnsavedRows(false);
        apiRef.current.updateRows(
            Object.values(unsavedChangesRef.current.rowsBeforeChange),
        );
        unsavedChangesRef.current = {
            unsavedRows: {},
            rowsBeforeChange: {},
        };
    };

    const onDownload = () => {
        try {
            setIsDownloading(true);
            var filename = 'FXRATES.txt';
            FxRateApi.downloadFxRateFileAsync()
                .then(response => {
                    const disposition = response.headers['content-disposition'];
                    filename = disposition.split('filename')[1];
                    if (filename != null)
                        filename = decodeURIComponent(filename.replace('=', '').replace(';', '').trim());
                    return response;
                })
                .then((res) => {
                    fileDownload(res.data, filename)
                    setIsDownloading(false);
                })
        } catch (error) {
            console.error(error);
            setIsDownloading(false);
            setSnackbar({ children: 'Something went wrong. Please try again!', severity: 'error' });
        }
    }

    const onPushFileToFTP = () => {
        try {
            setIsUploading(true);
            FxRateApi.pushFxRateFileAsync()
                .then((res) => {
                    setIsUploading(false);
                    if (res === true)
                        setSnackbar({ children: "File pushed to the designated location and email notification sent successfully!", severity: 'success' });
                }).catch((e) => {
                    setIsUploading(false);
                    console.error(e);
                    setSnackbar({ children: 'Something went wrong. Please try again!', severity: 'error' })
                });
        } catch (error) {
            setIsUploading(false);
            console.error(error);
            setSnackbar({ children: 'Something went wrong. Please try again!', severity: 'error' });
        }
    }

    const saveChanges = React.useCallback(
        async (lastMaintenanceDateTime: Date) => {
            try {
                setIsSaving(true);

                const rowsToUpdate = Object.values(
                    unsavedChangesRef.current.unsavedRows,
                )
                if (rowsToUpdate.length > 0) {
                    var response = await UpdateRow(rowsToUpdate,
                        lastMaintenanceDateTime);
                    setSnackbar({ children: response, severity: 'success' });
                }
                setIsSaving(false);
                setHasUnsavedRows(false);
                unsavedChangesRef.current = {
                    unsavedRows: {},
                    rowsBeforeChange: {},
                };
            }
            catch (error: any) {
                setIsSaving(false);
                setSnackbar({ children: error.message, severity: 'error' });
            }
            return '';
        },
        [UpdateRow]);

    return (
        <div style={{ width: '100%' }}>
            <Toolbar disableGutters>
                <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
                    <LoadingButton variant="outlined"
                        disabled={!hasUnsavedRows}
                        loading={isSaving}
                        onClick={() => saveChanges(lastMaintenanceDateTime)}
                        startIcon={<SaveIcon />}
                        loadingPosition="start">
                        <span>Save</span>
                    </LoadingButton>
                    <Button variant="outlined"
                        disabled={!hasUnsavedRows || isSaving}
                        onClick={discardChanges}
                        startIcon={<RestoreIcon />}>
                        Discard all changes
                    </Button>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <LoadingButton color="primary" variant="contained"
                        loading={isDownloading}
                        onClick={onDownload}
                        startIcon={<FileDownloadOutlinedIcon />}
                        loadingPosition="start">
                        <span>Download Fx Rate File</span>
                    </LoadingButton>
                    <LoadingButton color="primary" variant="contained"
                        loading={isUploading}
                        onClick={onPushFileToFTP}
                        startIcon={<FileUploadOutlinedIcon />}
                        loadingPosition="start">
                        <span>Push File to FTP</span>
                    </LoadingButton>
                </Stack>
            </Toolbar>
            <div style={{ width: '100%' }}>
                <DataGridPro
                    sx={{
                        '& .MuiDataGrid-row.row--removed': {
                            backgroundColor: (theme) => {
                                if (theme.palette.mode === 'light') {
                                    return 'rgba(255, 170, 170, 0.3)';
                                }
                                return darken('rgba(255, 170, 170, 1)', 0.7);
                            },
                        },
                        '& .MuiDataGrid-row.row--edited': {
                            backgroundColor: (theme) => {
                                if (theme.palette.mode === 'light') {
                                    return 'rgba(255, 254, 176, 0.3)';
                                }
                                return darken('rgba(255, 254, 176, 1)', 0.6);
                            },
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            textOverflow: "clip",
                            whiteSpace: "break-spaces",
                            lineHeight: 1
                        }
                    }}
                    autoHeight
                    headerHeight={80}
                    disableColumnMenu={true}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    pagination
                    columns={columns}
                    rows={rows}
                    editMode="row"
                    getRowId={(row) => row.id}
                    components={{ Toolbar: FxRateGridToolbar }}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    experimentalFeatures={{ newEditingApi: true }}
                    onCellDoubleClick={handleCellDoubleClick}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                    apiRef={apiRef}
                    getRowClassName={({ id }) => {
                        const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
                        if (unsavedRow) {
                            if (unsavedRow._action === 'delete') {
                                return 'row--removed';
                            }
                            return 'row--edited';
                        }
                        return '';
                    }}
                />
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </div>
        </div>
    );

});

export default FxRateGrid;