import { BaseService } from './base.service';
import type { AuthState } from '../store/authSlice';

/**
 * Auth API abstraction layer communication via Axios (typescript singleton pattern)
 */
class AuthService extends BaseService {
    private static _authService: AuthService;
    private static _controller: string = 'Auth';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): AuthService {
        return this._authService || (this._authService = new this(this._controller));
    }
    public async getUserInfoAsync(): Promise<AuthState> {
        const { data } = await this.$http.get<AuthState>('GetAuthStateAsync');
        return data;
    }
}

export const AuthApi = AuthService.Instance;
