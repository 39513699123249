import { type GridColumns } from "@mui/x-data-grid-pro";

const decimalFormatter = (value: any, precision: number) => {
    return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
    }).format(value)
};

const dateFormatter = (value: any) => {
    return new Intl.DateTimeFormat("en-US",
        {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        }).format(value).replace(',', '')
};

export const FxRateGridColumns = (): GridColumns => {
    return ([
        {
            field: "id",
            headerName: "Sr. No.",
            type: "number",
            editable: false,
            width: 70
        },
        {
            field: "currencyName",
            headerName: "Currency Name",
            editable: false,
            width: 160
        },
        {
            field: "currencyCode",
            headerName: "Currency Code",
            editable: false,
            width: 120
        },
        {
            field: "cfLastOriginal",
            headerName: "Reuters Fx Market Rate",
            type: 'number',
            editable: false,
            width: 120,
            valueFormatter: ({ value }) => decimalFormatter(value, 10)
        },
        {
            field: "spreadPercentage",
            headerName: "Spread (%)",
            type: 'number',
            editable: true,
            hideable:false,
            width: 100,
            valueFormatter: ({ value }) => decimalFormatter(value, 4)
        },
        {
            field: "cfLastMarkup",
            headerName: "EWB Markup",
            type: 'number',
            editable: false,
            width: 120,
            valueFormatter: ({ value }) => decimalFormatter(value, 10)
        },
        {
            field: "cfLastFinal",
            headerName: "EWB Final Fx Rate",
            type: 'number',
            editable: false,
            width: 110,
            valueFormatter: ({ value }) => decimalFormatter(value, 8)
        },
        {
            field: "cfLastFinalInverse",
            headerName: "Inverse EWB Final Fx Rate",
            type: 'number',
            editable: false,
            width: 130,
            valueFormatter: ({ value }) => decimalFormatter(value, 8)
        },
        {
            field: "lastMaintenanceDateTime",
            headerName: "Reuters Snapshot Time",
            width: 165,
            type: "date",
            editable: false,
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => dateFormatter(value)
        },
        {
            field: "webUpdatedBy",
            headerName: "Last Updated By",
            width: 190,
            editable: false
        },
        {
            field: "webupdatedDate",
            headerName: "Last Updated Date Time",
            width: 165,
            type: "date",
            editable: false,
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => dateFormatter(value)
        },
        {
            field: "currencyStatus",
            headerName: "Currency Status",
            type: "singleSelect",
            valueOptions: ["A", "M", "D"],
            editable: true,
            hideable: false,
            width: 110
        },
        {
            field: "isActive",
            headerName: "Is Active?",
            type: "boolean",
            editable: true,
            hideable: false,
            width: 100
        }
    ]);
};