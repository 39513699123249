import { BaseService } from './base.service';
import type { FxRatePageViewModel } from '../store/fxRateSlice';

/**
 * FxRate API abstraction layer communication via Axios (typescript singleton pattern)
 */
class FxRateService extends BaseService {
    private static _fxRateService: FxRateService;
    private static _controller: string = 'FxRate';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): FxRateService {
        return this._fxRateService || (this._fxRateService = new this(this._controller));
    }

    public async getRequestAsync(): Promise<FxRatePageViewModel> {
        const url = `GetFxRatePageRequest`;
        const { data } = await this.$http.get<FxRatePageViewModel>(url);
        return data;
    }

    public async saveFxRateRequestAsync(fxRateRequests: FxRatePageViewModel): Promise<boolean> {
        const { data } = await this.$http.post<boolean>('SaveFxRateRequest', fxRateRequests);
        return data;
    }

    public async downloadFxRateFileAsync(): Promise<any> {
        const url = `DownloadFxRateFile`;
        return this.$http.get<any>(url, {
            headers: {
                responseType: 'blob'
            }
        });
    }

    public async pushFxRateFileAsync(): Promise<boolean> {
        const { data } = await this.$http.post<boolean>('PushFxRateFile', null);
        return data;
    }
}

export const FxRateApi = FxRateService.Instance;
