import * as React from 'react';
import { type FunctionComponent } from 'react';
import { Spinner } from '../../components';
import { useAppDispatch, useAppSelector } from '../../store';
import { getRateSubscriptionsAsync, type RateSubscriptionModel } from '../../store/rateSubscriptionSlice';
import RateSubscriptionGrid from './RateSubscriptionGrid';

const RateSubscriptionPage: FunctionComponent = () => {
    const rateSubscriptionData = useAppSelector<RateSubscriptionModel[]>((state) => state.rateSubscription.rateSubscriptionData);
    const isLoading = useAppSelector<boolean>((state) => state.rateSubscription.isLoading);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(getRateSubscriptionsAsync());
    }, [dispatch]);

    return (
        <div className="section">
            <div className="container is-fluid">
                <h3 className="title is-3">
                    GTS Cross Border Fx Buy Rate
                </h3>
                <div className="box container-box">
                    <Spinner isLoading={isLoading} />
                    <RateSubscriptionGrid rateSubscriptionData={rateSubscriptionData} />
                </div>
            </div>
        </div>
    );
};

export default RateSubscriptionPage;
