import * as React from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes, DataGridPro,
    type GridRowModesModel,
    type GridColumns,
    type GridRowParams,
    type MuiEvent,
    GridActionsCellItem,
    type GridEventListener,
    type GridRowId,
    type GridRowModel
} from '@mui/x-data-grid-pro';
import {
    deleteSubscriptionAsync,
    getRateSubscriptionsAsync, postSubscriptionAsync, type RateSubscriptionModel
} from '../../store/rateSubscriptionSlice';
import { useAppDispatch } from '../../store';
import { Alert, Snackbar, type AlertProps } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import RateSubscriptionGridToolbar from './RateSubscriptionGridToolbar';
interface RateSubscriptionGridProps {
    rateSubscriptionData: RateSubscriptionModel[]
}
const RateSubscriptionGrid = React.memo<RateSubscriptionGridProps>(({ rateSubscriptionData }) => {
    const confirm = useConfirm();
    const dispatch = useAppDispatch();

    const [pageSize, setPageSize] = React.useState<number>(5);
    const [page, setPage] = React.useState<number>(0);
    const [rows, setRows] = React.useState<RateSubscriptionModel[]>(rateSubscriptionData);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const [snackbar, setSnackbar] = React.useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    React.useEffect(() => {
        setRows(rateSubscriptionData);
    }, [rateSubscriptionData]);

    const UpdateRow = React.useCallback(
        (newRow: GridRowModel) =>
            new Promise<Partial<RateSubscriptionModel>>(async (resolve, reject) => {
                if (newRow.companyId == null || newRow.companyId.trim() === '') {
                    reject(new Error("Error while saving data: companyId can't be empty."));
                }
                else if (newRow.emailAddress == null || newRow.emailAddress.trim() === '') {
                    reject(new Error("Error while saving data: emailAddress can't be empty."));
                } else {
                    const data = { id: newRow.id, companyId: newRow.companyId, emailAddress: newRow.emailAddress };
                    const newLookup = await dispatch(postSubscriptionAsync(data));
                    if (newLookup.payload.id != null) {
                        setRows((oldRows) => ([...oldRows.filter((row) => row.id !== 0), newLookup.payload]));
                        resolve(newLookup.payload);
                    }
                    else
                        reject(new Error("Error while saving data: " + newLookup.payload));
                }
            }),
        [dispatch]
    );
    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel) => {
            const response = await UpdateRow(newRow);
            setSnackbar({ children: 'Record added successfully.', severity: 'success' });
            await dispatch(getRateSubscriptionsAsync());
            setPage(0);
            return response;
        },
        [UpdateRow]);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const DeleteRow = React.useCallback(
        (id: number) =>
            new Promise<Partial<boolean>>(async (resolve, reject) => {
                var response = await dispatch(deleteSubscriptionAsync(id));
                if (response.payload === true) {
                    setRows(rows.filter((row) => row.id !== id));
                    resolve(response.payload);
                }
                else
                    reject(new Error("Error while deleting record: " + response.payload));
            }),
        [dispatch],
    );

    const handleDeleteClick = (id: GridRowId) => async () => {
        if (id === 0) {
            handleCancelClick(id);
        } else {
            confirm({ description: `Are you sure to delete this record with Id:${id}?` })
                .then(async () => {
                    var response = await DeleteRow(parseInt(id.toString()));
                    if (response) {
                        setSnackbar({ children: 'Record deleted successfully.', severity: 'success' });
                        await dispatch(getRateSubscriptionsAsync());
                    }
                })
                .catch(() => setSnackbar({ children: 'Deletion cancelled.', severity: 'success' }));
        }
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.id === 0) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const columns: GridColumns = [
        {
            field: 'id', headerName: 'Id', type: 'number', editable: false, minWidth: 100, hideable: false
        },
        {
            field: 'companyId', headerName: 'Company Id', editable: true, flex: 1, hideable: false
        },
        {
            field: 'emailAddress', headerName: 'Email Address', editable: true, flex: 1, hideable: false
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            hideable: false,
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGridPro
                disableSelectionOnClick
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                pagination
                rows={rows}
                columns={columns}
                editMode="row"
                getRowId={(row) => row.id}
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                components={{
                    Toolbar: RateSubscriptionGridToolbar,
                }}
                componentsProps={{
                    toolbar: { setRows, setRowModesModel, setPage, pageSize },
                }}
                experimentalFeatures={{ newEditingApi: true }}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Box>
    );
});

export default RateSubscriptionGrid;