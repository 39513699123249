import authReducer from './authSlice';
import fxRateReducer from './fxRateSlice';
import rateSubscriptionReducer from './rateSubscriptionSlice';
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        fxRate: fxRateReducer,
        rateSubscription: rateSubscriptionReducer,
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {auth: AuthState, form: FormState, weather: WeatherState}
export type AppDispatch = typeof store.dispatch;