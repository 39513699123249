import * as React from 'react';
import {
    GridToolbar,
} from '@mui/x-data-grid-pro';
import { Box, Toolbar, Typography } from '@mui/material';

interface BulkEditGridToolbarProps {
    setPage: (newPage: (oldPage: number) => number) => void;
}

const BulkEditGridToolbar = React.memo<BulkEditGridToolbarProps>((props: BulkEditGridToolbarProps) => {

    return (
        <Toolbar>
            <GridToolbar csvOptions={{ disableToolbarButton: true }} printOptions={{ disableToolbarButton: true }} />
            <Box sx={{ flex: 1, textAlign: 'right' }}>
                <Typography variant="subtitle2">
                    <b>Currency Status Legends:</b>  A = Add, M = Modify, D = Delete
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    <b>Please Note:</b> Time zone displayed on this website is Pacific Standard Time (PST)
                </Typography>
            </Box>
        </Toolbar>
    )
});

export default BulkEditGridToolbar;