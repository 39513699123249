import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StrictMode, Fragment } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import App from './App';
import './assets/style/scss/site.scss';
import { store } from './store';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import { toastifyProps, registerIcons, MUI_THEME_CONFIG } from './config';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PublicClientApplication, EventType, type EventMessage, type AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from './config/auth.config';
import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';

console.log("ClientId: " + msalConfig.auth.clientId);
//console.log("test log");
export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_Key || '');

registerIcons();

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

function AppRenderer() {
    const theme = createTheme(MUI_THEME_CONFIG);
    return (
        <Fragment>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <Provider store={store}>
                        <ConfirmProvider>
                            {/*Strict mode renders components twice in developmetn mode*/}
                            <StrictMode>
                                <CssBaseline />
                                <App pca={msalInstance} />
                            </StrictMode>
                        </ConfirmProvider>
                    </Provider>
                </ThemeProvider>
            </BrowserRouter>
            <ToastContainer {...toastifyProps} />
        </Fragment>
    );
}

root.render(<AppRenderer />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
