import { FxRateApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

export type FxRatePageViewModel = Readonly<{
    fxRateRequests: FxRatePageModel[],
    lastMaintenanceDateTime: Date,
    isUserReadOnly: boolean
}>;

export type FxRatePageModel = Readonly<{
    id: number,
    currencyMasterId: number;
    cfLastOriginal: number;
    cfLastMarkup: number;
    cfLastFinal: number;
    cfLastFinalInverse: number;
    spreadPercentage: number;
    lastMaintenanceDateTime: Date | undefined;
    webUpdatedDate: Date | undefined;
    webUpdatedBy: string;
    isActive: boolean;
    currencyStatus: string;
    currencyName: string;
    currencyCode: string;
    precision: number;
}>;

export type FxRatePageState = Readonly<{
    isLoading: boolean;
    fxRateData: FxRatePageViewModel;
}>;

export type ReceiveRequestsPayload = Pick<FxRatePageState, "fxRateData">;

const initialState: FxRatePageState = {
    fxRateData: { fxRateRequests: [], lastMaintenanceDateTime: new Date(), isUserReadOnly: true },
    isLoading: false
};

export const FxRateSlice = createSlice({
    name: 'FxRate',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        receiveRequests: (state, action: PayloadAction<ReceiveRequestsPayload>) => {
            state.isLoading = false;
            const { fxRateData } = action.payload;
            state.fxRateData = fxRateData;
        }
    }
});

export const getRequestsAsync = createAsyncThunk(
    'fxRate/getRequestsAsync',
    async (_, { dispatch }) => {

        // Dispatch request to intialize loading phase
        dispatch(startLoading());

        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            const fxRateData = await FxRateApi.getRequestAsync();
            dispatch(stopLoading());
            const payload = { fxRateData };
            dispatch(receiveRequests(payload));
            return null;
        } catch (e) {
            dispatch(stopLoading());
            console.error(e);
        }
    }
);

export const saveFxRateRequestAsync = createAsyncThunk(
    'fxRate/saveFxRateRequestAsync',
    async (fxRateRequest: FxRatePageViewModel, { dispatch }) => {
        try {
            dispatch(startLoading());
            const response = await FxRateApi.saveFxRateRequestAsync(fxRateRequest);
            dispatch(getRequestsAsync());
            return response;
        } catch (e: any | AxiosError) {
            dispatch(stopLoading());
            console.error(e);
            if (axios.isAxiosError(e)) {
                var type = typeof (e.response?.data);
                return type === 'string' ? e.message + (typeof (e.response?.data) === 'string' ? ": " + e.response?.data : "") : e.response?.data;
            }
            return e.response.statusText;
        }
    }
);

export const { startLoading, stopLoading, receiveRequests } = FxRateSlice.actions;

export default FxRateSlice.reducer;