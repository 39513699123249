import type { ThemeOptions } from '@mui/material';
export const MUI_THEME_CONFIG: ThemeOptions = {
    palette: {
        //primary: teal,
        primary: {
            main: '#005daa', //this overide blue color
            light: '#005daa', //overides light blue
            dark: '#005daa', //overides dark blue color
            contrastText: '#fff'
        },
        action: {
            focus: '#005daa',
            focusOpacity: 0.5,
            selected: '#005daa',
            //selectedOpacity: 1,
            active: '#005daa',
            //activatedOpacity: 1,
            hover: '#a9d1f3'
        }
    },
};
