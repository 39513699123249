import Layout from './Layout';
import { type FunctionComponent } from 'react';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, type IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from './config/auth.config';
import { ErrorComponent } from './components/ErrorComponent';
import { Loading } from './components/Loading';
import PrivateRoutes from './components/PrivateRoutes';

type AppProps = {
    pca: IPublicClientApplication;
};
const App: FunctionComponent<AppProps> = ({ pca }) => {

    const authRequest = {
        ...loginRequest
    };
    return (
        <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorComponent}
                loadingComponent={Loading}
            >
                <Layout>
                    <PrivateRoutes />
                </Layout>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
};

export default App;