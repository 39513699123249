import * as React from 'react';
import { type FunctionComponent } from 'react';
import { Spinner } from '../../components';
import { useAppDispatch, useAppSelector } from '../../store';
import { getRequestsAsync, type FxRatePageViewModel } from '../../store/fxRateSlice';
import FxRateGrid from './FxRateGrid';
import FxRateGridReadOnly from './FxRateGridReadOnly';

const FxRatePage: FunctionComponent = () => {
    const fxRateData = useAppSelector<FxRatePageViewModel>((state) => state.fxRate.fxRateData);
    const isLoading = useAppSelector<boolean>((state) => state.fxRate.isLoading);

    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(getRequestsAsync());
    }, [dispatch]);
    if (fxRateData.isUserReadOnly) {
        return (
            <div className="section" >
                <div className="container is-fluid">
                    <h3 className="title is-3">
                        GTS Cross Border Fx Buy Rate
                    </h3>
                    <div className="box container-box">
                        <Spinner isLoading={isLoading} />
                        <FxRateGridReadOnly fxRateData={fxRateData} />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="section" >
            <div className="container is-fluid">
                <h3 className="title is-3">
                    GTS Cross Border Fx Buy Rate
                </h3>
                <div className="box container-box nopaddingTop">
                    <Spinner isLoading={isLoading} />
                    <FxRateGrid fxRateData={fxRateData} />
                </div>
            </div>
        </div>
    );
};

export default FxRatePage;
