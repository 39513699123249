import * as React from 'react';
import {
    DataGridPro,
    type GridColumnVisibilityModel,
    type GridColDef
} from '@mui/x-data-grid-pro';
import { type FxRatePageModel, type FxRatePageViewModel } from '../../store/fxRateSlice';
import { FxRateGridColumns } from './FxRateGridColumns';
import FxRateGridToolbar from './FxRateGridToolbar';

interface FxRateGridProps {
    fxRateData: FxRatePageViewModel
}
const FxRateGridReadOnly = React.memo<FxRateGridProps>(({ fxRateData }) => {
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [page, setPage] = React.useState<number>(0);
    const [rows, setRows] = React.useState<FxRatePageModel[]>(fxRateData.fxRateRequests);
    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({
            id: true,
            currencyMasterId: false,
            currencyName: true,
            currencySymbol: true,
            terms: true,
            offerSpreadPct: true,
            offerMarketRate: true,
            offerSpreadBps: true,
            offerCustomerRateInternal: true,
            offerCustomerRateExternal: true,
            bidSpreadPct: true,
            bidMarketRate: true,
            bidSpreadBps: true,
            bidCustomerRateInternal: true,
            bidCustomerRateExternal: true,
            fxlastMaintDateTime: true,
            updatedDate: true,
            updatedBy: true,
            rateFileCreated: false,
            fileUpdateType: true,
            isActive: true
        });

    React.useEffect(() => {
        setRows(fxRateData.fxRateRequests);
    }, [fxRateData.fxRateRequests]);

    const gridColumns = FxRateGridColumns();
    const columns = React.useMemo<GridColDef[]>(() => {
        return [
            ...gridColumns,
        ];
    }, [gridColumns]);

    return (
        <div style={{ width: '100%' }}>
            <DataGridPro
                sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        textOverflow: "clip",
                        whiteSpace: "break-spaces",
                        lineHeight: 1
                    }
                }}
                autoHeight
                headerHeight={80}
                disableColumnMenu={true}
                disableSelectionOnClick
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20, 50]}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                pagination
                columns={columns}
                rows={rows}
                editMode="row"
                getRowId={(row) => row.id}
                isCellEditable={() => false}
                components={{ Toolbar: FxRateGridToolbar }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
            />
        </div>
    );

});

export default FxRateGridReadOnly;