import { BaseService } from './base.service';
import type { RateSubscriptionModel } from '../store/rateSubscriptionSlice';

/**
 * RateSubscription API abstraction layer communication via Axios (typescript singleton pattern)
 */
class RateSubscriptionService extends BaseService {
    private static _rateSubscriptionService: RateSubscriptionService;
    private static _controller: string = 'RateSubscription';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): RateSubscriptionService {
        return this._rateSubscriptionService || (this._rateSubscriptionService = new this(this._controller));
    }

    public async getRateSubscriptions(): Promise<RateSubscriptionModel[]> {
        const url = `GetRateSubscriptions`;
        const { data } = await this.$http.get<RateSubscriptionModel[]>(url);
        return data;
    }

    public async postSubscriptionAsync(rateSubscription: RateSubscriptionModel): Promise<any> {
        const { data } = await this.$http.post<boolean>('AddSubscription', rateSubscription);
        return data;
    }

    public async deleteSubscriptionAsync(id: number): Promise<any> {
        const url = `DeleteSubscription?id=${id}`;
        const { data } = await this.$http.delete<boolean[]>(url);
        return data;
    }
}

export const RateSubscriptionApi = RateSubscriptionService.Instance;
